/* istanbul ignore file */
import { type ContentType } from '@lumapps/content-types/types';
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import type { LegacyContentStatus } from '../types';

const contentAdminRoute: Route = {
    path: '/admin/content/:uid',
    legacyId: 'app.admin.content',
    appId: AppId.legacy,
};

const contentEditPrefix = '/content/edit/';
const contentCreatePrefix = '/content/create/';
const contentCopyPrefix = '/content/duplicate/';

const contentEditRoute: Route = {
    // The param names should be the same as in the legacy state.js $stateParams
    path: `${contentEditPrefix}:key`,
    params: {
        contentType: 'content',
        // The content id.
        key: undefined,
    },
    legacyId: 'app.front.content-edit',
    appId: AppId.legacy,
};

const contentCreateRoute: Route = {
    // The param names should be the same as in the legacy state.js $stateParams
    path: `${contentCreatePrefix}:type`,
    params: {
        // The content type.
        type: undefined,
    },
    legacyId: 'app.front.content-create',
    appId: AppId.legacy,
};

const contentCopyRoute = {
    // The param names should be the same as in the legacy state.js $stateParams
    path: `${contentCopyPrefix}:key`,
    params: {
        // The content id.
        key: undefined,
    },
    legacyId: 'app.front.content-duplicate',
    appId: AppId.legacy,
};

export const isDesignerUrl = (slug: string) => {
    return slug.indexOf(contentEditPrefix) === 0 || slug.indexOf(contentCreatePrefix) === 0;
};

export const adminContent = (uid: string): Route => ({
    ...contentAdminRoute,
    params: { customContentType: uid, uid },
});

export const contentEdit = (contentId: string): Route => ({
    ...contentEditRoute,
    params: {
        ...contentEditRoute.params,
        key: contentId,
    },
});

export const contentCreate = (type: ContentType): Route => ({
    ...contentCreateRoute,
    params: {
        ...contentEditRoute.params,
        type,
    },
});

export const contentCopy = (contentId: string, status: LegacyContentStatus): Route => ({
    ...contentCopyRoute,
    params: {
        key: contentId,
        status,
    },
});
