import React from 'react';

import { Orientation } from '@lumapps/lumx/react';

import { MenubarProps } from '../Menubar/types';

export type HoveredCallback = (isHovered: boolean) => void;

export type SubscribeMenuHovered = (triggerElement: Element, cb: HoveredCallback) => void;

export type MenuContextOptions = {
    /** Menu is loading. */
    isLoading?: boolean;
    /** Menu orientation */
    orientation: Orientation;
    /** Close the topmost menu. */
    closeRoot?(): void;
    /** Is the topmost menu. */
    isRoot?: boolean;
    /** Menubar variant */
    menubarVariant?: Pick<MenubarProps, 'size' | 'theme' | 'orientation'>;
};

export const MenuContext = React.createContext<MenuContextOptions | undefined>(undefined);
