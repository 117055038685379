import React from 'react';

import { classnames } from '@lumapps/classnames';
import { GenericProps } from '@lumapps/lumx/react';

import { invertOrientation } from '../../../utils/invertOrientation';
import { BASE_CLASSNAME } from '../constants';
import { MenuContext } from '../context';

const CLASSNAME = `${BASE_CLASSNAME}-divider`;

/**
 * Menu divider (ARIA `separator`).
 *
 * To use inside a `Menu` or `Menubar`.
 *
 * @family Menus
 */
export const MenuDivider = React.forwardRef<HTMLLIElement, GenericProps>(({ className }, ref) => {
    const context = React.useContext(MenuContext);
    return (
        <li
            ref={ref}
            role="separator"
            className={classnames(
                className,
                CLASSNAME,
                context?.menubarVariant && context.menubarVariant.orientation === 'horizontal'
                    ? 'lumx-menubar-divider'
                    : 'lumx-list-divider',
            )}
            aria-orientation={invertOrientation(context?.orientation)}
        />
    );
});
MenuDivider.displayName = 'MenuDivider';
